/* App.css */
:root {
  --primary: #FE6C00; /* Define your color */
  --secondary: #ff7849; /* Define your color */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height */
}

.options-container {
  display: flex;
  flex-direction: column; /* Adjust based on your layout */
  gap: 16px;
}

.content {
  display: flex;
  flex-direction: row; /* Adjust based on your layout */
  justify-content: space-between;
  padding: 20px;
}

.leftSide {
  display: flex;
  flex-direction: column; /* Adjust based on your layout */
  gap: 16px;
  flex-grow: 1;
}

.dropdowns {
  display: flex;
  flex-direction: column; /* Or row, depending on your layout */
  gap: 16px;
  flex-grow: 1;
}

.features-container {
  border-radius: 12px;
  display: flex;
  flex-direction: row; /* Or column, depending on your layout */
  justify-content: stretch;
  flex-grow: 1;
}

.linksAndToggles {
  display: flex;
  flex-direction: column; /* Or row, depending on your layout */
  gap: 16px;
  padding: 0px 16px;
}

.headerText {
  color: #BDBABB;
  font-family: Bai Jamjuree;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.subheaderText {
  color: #BDBABB;
  font-family: Bai Jamjuree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.usageBarText {
  color: #BDBABB;
  font-family: Bai Jamjuree;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.limitText {
  color: #BDBABB;
  text-align: right;
  font-family: Bai Jamjuree;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 8px;
}

/* Assuming your div has a class of .custom-scroll */
.custom-scroll::-webkit-scrollbar {
  width: 12px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f0f0f000;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 6px;
  border: 2px solid #f0f0f0;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background:  var(--primary);
}

/* For Firefox */
.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) #f0f0f0;
}
